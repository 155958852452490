import axios from "./axios";

class ApiService {
  get(id) {
    return axios.get(`/${id}`);
  }

  getList(pagination, category) {
    return axios.get(`/${category}`, {
      params: {
        page: pagination.current,
      },
    });
  }

  post(post, category) {
    return axios.post(`/${category}`, post);
  }

  put(post, id) {
    return axios.put(`/${id}`, post);
  }

  delete(id) {
    return axios.delete(`/${id}`);
  }
}

export default new ApiService();
