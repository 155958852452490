import React, { useState } from "react";
import { Layout, Button, Form, Input, Alert, Typography } from "antd";
import styled from "styled-components";
import AuthService from "../services/auth";

const { Content } = Layout;

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
  size: "large",
};

function Login() {
  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState(false);

  const handleLogin = ({ username, password }) => {
    setLoading(true);
    AuthService.login(username, password)
      .then((response) => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        setLoginError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Layout
      style={{
        minHeight: "100vh",
        backgroundColor: "white",
        backgroundImage:
          "linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.6) ), url(/background.jpg)",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <ContentStyled>
        <Form
          {...layout}
          onFinish={handleLogin}
          style={{
            width: "380px",
            padding: 48,
            borderRadius: 8,
            backgroundColor: "white",
          }}
        >
          <Form.Item>
            <img
              style={{ width: "180px", filter: "invert(1)", marginBottom: 16 }}
              src="/logo-white.png"
            ></img>
          </Form.Item>
          {loginError ? (
            <Form.Item>
              <Alert
                style={{ width: "100%" }}
                description="입력한 계정 정보가 정확하지 않습니다."
                type="error"
              />
            </Form.Item>
          ) : (
            ""
          )}
          <Form.Item
            label="아이디"
            name="username"
            rules={[
              {
                required: true,
                message: "아이디를 입력하세요.",
              },
            ]}
          >
            <Input
              style={{
                borderColor: "#0080a3",
              }}
            ></Input>
          </Form.Item>
          <Form.Item
            label="비밀번호"
            name="password"
            rules={[
              {
                required: true,
                message: "비밀번호를 입력하세요.",
              },
            ]}
          >
            <Input.Password
              style={{
                borderColor: "#0080a3",
              }}
            ></Input.Password>
          </Form.Item>
          <Form.Item style={{ margin: 0 }}>
            <Button
              type="primary"
              style={{
                width: "100%",
                marginTop: 24,
                backgroundColor: "#0080a3",
                borderColor: "#0080a3",
              }}
              htmlType="submit"
              loading={loading}
            >
              로그인
            </Button>
          </Form.Item>
        </Form>
      </ContentStyled>
    </Layout>
  );
}

const ContentStyled = styled(Content)`
  display: flex;
  justify-content: center;
  align-items: center;

  .ant-form-item-label {
    text-align: left;
  }

  .ant-col-16 {
    max-width: 100%;
  }

  .ant-form-item-control-input-content {
    display: flex;
    justify-content: center;
  }
`;

export default Login;
