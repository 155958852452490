import { Button, message, Popconfirm, Space, Table, Typography } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import ApiService from "../services/api";
import queryString from "query-string";
import styled from "styled-components";

const { Title } = Typography;

const columns = [
  {
    title: "아이디",
    dataIndex: "_id",
    key: "_id",
    width: "20%",
  },
  {
    title: "제목",
    dataIndex: "subject",
    key: "subject",
    width: "20%",
  },
  {
    title: "작성일",
    dataIndex: "created_time",
    key: "created_time",
    width: "20%",
  },
  {
    title: "시작일",
    dataIndex: "start_time",
    key: "start_time",
    width: "20%",
  },
  {
    title: "종료일",
    dataIndex: "end_time",
    key: "end_time",
    width: "20%",
  },
];

const ApiTable = (props) => {
  const pathname = props.location.pathname;
  const category = pathname.split("/").pop().toUpperCase();
  const [url, setUrl] = useState(pathname);
  const [value, setValue] = useState({
    data: [],
    pagination: {
      current: Number(queryString.parse(props.location.search).page) || 1,
      pageSize: 9,
      onChange(page, pageSize) {
        props.history.push(pathname + "?page=" + page);
      },
    },
    loading: false,
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  useEffect(() => {
    columns[0].render = columnIdRender;

    const { pagination } = value;
    fetch({ pagination });
  }, []);

  const columnIdRender = (_id) => {
    return <Link to={`${url}/${_id}`}>{_id}</Link>;
  };

  const handleTableChange = (pagination) => {
    fetch({ pagination });
  };

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const fetch = ({ pagination }) => {
    setValue({ loading: true });
    ApiService.getList(pagination, category).then(({ data }) => {
      data.list.map((item) => {
        item["key"] = item._id;
        item.created_time = moment(item.created_time).format(
          "YYYY-MM-DD HH:MM"
        );
        item.start_time = moment(item.start_time).format("YYYY-MM-DD");
        item.end_time = moment(item.end_time).format("YYYY-MM-DD");
        return item;
      });

      setValue({
        loading: false,
        data: data.list,
        pagination: {
          ...pagination,
          total: data.count,
        },
      });
    });
  };

  const confirm = () => {
    if (!selectedRowKeys.length) {
      return message.warn("게시글을 선택해주세요.");
    }
    setValue({ ...value, loading: true });

    let errorPost = 0;

    selectedRowKeys.map((key, index) => {
      ApiService.delete(`${category}/${key}`)
        .then(() => {
          if (selectedRowKeys.length === index + 1) {
            props.history.replace(pathname);
            message.success("게시글을 성공적으로 삭제하였습니다.");
          }
        })
        .catch(() => {
          errorPost += 1;
          if (selectedRowKeys.length === index + 1) {
            message.error(`${errorPost}개의 게시글 삭제가 실패하였습니다.`);
          }
        })
        .finally(() => {
          setValue({ ...value, loading: false });
        });
    });
  };

  return (
    <>
      <div
        className="site-layout-background"
        style={{ padding: 48, minHeight: 360 }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Title level={3} style={{ width: "100%" }}>
            {category}
          </Title>
          <Space
            style={{
              marginBottom: 16,
            }}
          >
            <div
              style={{
                marginRight: 4,
                textAlign: "right",
                width: 100,
                fontWeight: "bold",
              }}
            >
              {selectedRowKeys.length
                ? `${selectedRowKeys.length}개 선택됨`
                : ""}
            </div>
            <Link to={`/editor?category=${pathname}`}>
              <Button
                type="primary"
                icon={<EditOutlined />}
                style={{
                  backgroundColor: "#0080a3",
                  borderColor: "#0080a3",
                }}
              >
                생성
              </Button>
            </Link>
            <Popconfirm
              title="게시글을 삭제하시겠습니까?"
              placement="bottomRight"
              onConfirm={confirm}
              okText="예"
              cancelText="아니오"
            >
              <Button icon={<DeleteOutlined />}>삭제</Button>
            </Popconfirm>
          </Space>
        </div>
        <HeadStyeldTable
          bordered
          id="table1"
          rowSelection={{
            selectedRowKeys,
            onChange: onSelectChange,
          }}
          columns={columns}
          dataSource={value.data}
          pagination={value.pagination}
          loading={value.loading}
          onChange={handleTableChange}
        />
      </div>
    </>
  );
};

const HeadStyeldTable = styled(Table)`
  thead .ant-table-cell {
    font-weight: bold;
    font-size: medium;
  }
`;

export default withRouter(ApiTable);
