import {
  Button,
  Descriptions,
  Space,
  message,
  Typography,
  Popconfirm,
} from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import ApiService from "../services/api";
import styled from "styled-components";

const { Title } = Typography;

function Post(props) {
  const pathname = props.location.pathname;
  const pathnameItems = pathname.split("/");
  const pathId = `${pathnameItems[2]}/${pathnameItems[3]}`;
  const isGallery = pathnameItems[2].toUpperCase() === "GALLERY";
  const [value, setValue] = useState({});

  useEffect(() => {
    ApiService.get(pathId).then(({ data }) => {
      setValue(data.current);
    });
  }, []);

  const confirm = () => {
    ApiService.delete(pathId)
      .then(() => {
        props.history.goBack();
        message.success("게시글을 삭제하였습니다.");
      })
      .catch(() => {
        message.error("게시글 삭제가 실패하였습니다.");
      });
  };

  return (
    <PostStyle>
      <div className="site-layout-background" style={{ padding: 48 }}>
        <Descriptions
          style={{ maxWidth: 800 }}
          title={<Title level={3}>게시글 정보</Title>}
          layout="vertical"
          column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
          bordered
        >
          <Descriptions.Item label="제목">{value.subject}</Descriptions.Item>
          {isGallery || (
            <Descriptions.Item label="설명">
              {value.description}
            </Descriptions.Item>
          )}
          <Descriptions.Item label="썸네일">
            {value.thumbnail ? (
              <img style={{ width: "100%" }} src={value.thumbnail}></img>
            ) : (
              <span style={{ color: "#aaaaaa" }}>썸네일 지정되지 않음</span>
            )}
          </Descriptions.Item>
          <Descriptions.Item label="작성일">
            {moment(value.created_time).format("YYYY-MM-DD HH:MM")}
          </Descriptions.Item>
          <Descriptions.Item label="기간">
            {`${moment(value.start_time).format("YYYY-MM-DD")} ~ ${moment(
              value.end_time
            ).format("YYYY-MM-DD")}`}
          </Descriptions.Item>
          <Descriptions.Item label="내용">
            <Content
              dangerouslySetInnerHTML={{ __html: value.content }}
            ></Content>
          </Descriptions.Item>
        </Descriptions>
        <Space style={{ marginTop: 16 }}>
          <Link to={`/editor?post=${pathId}`}>
            <Button
              type="primary"
              icon={<EditOutlined />}
              style={{
                backgroundColor: "#0080a3",
                borderColor: "#0080a3",
              }}
            >
              수정
            </Button>
          </Link>
          <Popconfirm
            title="게시글을 삭제하시겠습니까?"
            onConfirm={confirm}
            okText="예"
            cancelText="아니오"
          >
            <Button icon={<DeleteOutlined />}>삭제</Button>
          </Popconfirm>
        </Space>
      </div>
    </PostStyle>
  );
}

const Content = styled.div`
  img {
    width: 100%;
  }
`;

const PostStyle = styled.div`
  .ant-descriptions-item-label {
    font-weight: bold;
    font-size: medium;
  }
`;

export default withRouter(Post);
