import {
  UploadOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Form,
  Input,
  message,
  Popconfirm,
  Space,
  Typography,
  Upload,
} from "antd";
import moment from "moment";
import queryString from "query-string";
import React, { Component } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import ApiService from "../services/api";
import axios from "../services/axios";

const Font = ReactQuill.Quill.import("formats/font");
Font.whitelist = [
  "GmarketSansLight",
  "GmarketSansMedium",
  "GmarketSansBold",
  "NanumBarunGothic",
  "RIDIBatang",
  "slick",
  "IBM_Plex_Mono",
  "IBM_Plex_Sans",
];
ReactQuill.Quill.register(Font, true);

const { Title } = Typography;

class Editor extends Component {
  query = queryString.parse(this.props.location.search);

  modules = {
    toolbar: [
      [{ font: Font.whitelist }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { align: "" },
        { align: "center" },
        { align: "right" },
        { align: "justify" },
      ],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  formats = [
    "font",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "align",
  ];

  category =
    this.query.category?.split("/")[2].toUpperCase() ||
    this.query.post?.split("/")[0].toUpperCase();

  postId = this.query.post || "";

  isGallery = this.category === "GALLERY";

  state = {
    actionTitle: this.query.post ? "수정" : "작성",
    value: {
      subject: null,
      start_time: null,
      end_time: null,
      description: null,
      thumbnail: null,
      content: null,
    },
  };

  componentDidMount() {
    if (this.query.post) {
      ApiService.get(this.query.post)
        .then(({ data }) => {
          this.setState({
            ...this.state,
            value: {
              ...this.state.value,
              ...data.current,
              start_time: moment(data.current.start_time),
              end_time: moment(data.current.end_time),
              thumbnail:
                data.current.thumbnail && data.current.thumbnail_name
                  ? [
                      {
                        uid: "-1",
                        status: "done",
                        name: data.current.thumbnail_name,
                        url: data.current.thumbnail,
                      },
                    ]
                  : [],
            },
          });
        })
        .catch((err) => console.log(err));
    }

    const editor = this.refs.editor.getEditor();

    editor.getModule("toolbar").addHandler("image", async () => {
      const images = this.refs.images;
      images.click();
    });
  }

  confirm = () => {
    this.props.history.goBack();
    message.info(`게시글 ${this.state.actionTitle}을 취소하였습니다.`);
  };

  onChangeThumbnail = (info) => {
    let fileList = info.fileList;

    fileList = fileList.map((file) => {
      if (file.response) {
        file.url = file.response.file;
      }
      return file;
    });

    this.setState({
      ...this.state,
      value: {
        ...this.state.value,
        thumbnail: fileList,
      },
    });
  };

  onChangeImages = async (e) => {
    let promiseList = [];

    for (let i = 0; i < e.target.files.length; i++) {
      promiseList = [
        ...promiseList,
        (async (file) => {
          try {
            const formData = new FormData();
            formData.append("files", file);

            const result = await axios.post("upload", formData);
            return result.data;
          } catch {
            return null;
          }
        })(e.target.files[i]),
      ];
    }

    const results = await Promise.all(promiseList);

    const editor = this.refs.editor.getEditor();
    results
      .filter((item) => item !== null)
      .forEach((item) => {
        let index = 0;
        const range = editor.getSelection();
        if (range) {
          index = range.index + range.length;
        } else {
          index = editor.getLength();
        }

        editor.insertEmbed(index, "image", item.file);
        editor.setSelection(index + 1);
      });

    this.refs.images.value = null;
  };

  onFinish = () => {
    if (this.state.actionTitle === "수정") {
      ApiService.put(this.state.value, this.postId)
        .then(() => {
          this.props.history.goBack();
          message.success("게시글을 수정하였습니다.");
        })
        .catch((err) => {
          console.log(err.response.status);
          message.error(
            "게시글을 수정하지 못했습니다. 내용을 확인하시고 다시 시도해 주세요."
          );
        });
    } else {
      ApiService.post(this.state.value, this.category)
        .then(() => {
          this.props.history.goBack();
          message.success("게시글을 작성하였습니다.");
        })
        .catch((err) => {
          console.log(err);
          message.error(
            "게시글을 작성하지 못했습니다. 내용을 확인하시고 다시 시도해 주세요."
          );
        });
    }
  };

  render() {
    return (
      <div
        className="site-layout-background"
        style={{ padding: 48, minHeight: 360, maxWidth: 800 }}
      >
        <Form>
          <Form.Item>
            <Title level={3}>
              {this.category} {this.state.actionTitle}
            </Title>
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: "제목을 입력하세요.",
              },
            ]}
          >
            <Input
              name="subject"
              autoComplete="off"
              placeholder="제목"
              value={this.state.value.subject}
              onChange={(event) =>
                this.setState({
                  ...this.state,
                  value: {
                    ...this.state.value,
                    [event.target.name]: event.target.value,
                  },
                })
              }
            ></Input>
          </Form.Item>

          <div style={{ display: "flex" }}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "시작일을 입력하세요.",
                },
              ]}
              style={{ flex: 1 }}
            >
              <DatePicker
                autoComplete="off"
                name="start_time"
                placeholder="시작일"
                style={{ width: "100%" }}
                value={this.state.value.start_time}
                onChange={(start_time) =>
                  this.setState({
                    ...this.state,
                    value: { ...this.state.value, start_time },
                  })
                }
              ></DatePicker>
            </Form.Item>
            <div style={{ width: 8 }}></div>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "시작일을 입력하세요.",
                },
              ]}
              style={{ flex: 1 }}
            >
              <DatePicker
                autoComplete="off"
                placeholder="종료일"
                style={{ width: "100%" }}
                value={this.state.value.end_time}
                name="end_time"
                onChange={(end_time) =>
                  this.setState({
                    ...this.state,
                    value: { ...this.state.value, end_time },
                  })
                }
              ></DatePicker>
            </Form.Item>
          </div>
          {this.isGallery || (
            <Form.Item>
              <Input
                autoComplete="off"
                name="description"
                placeholder="설명"
                value={this.state.value.description}
                onChange={(event) =>
                  this.setState({
                    ...this.state,
                    value: {
                      ...this.state.value,
                      [event.target.name]: event.target.value,
                    },
                  })
                }
              ></Input>
            </Form.Item>
          )}

          <Form.Item>
            <Quill
              ref="editor"
              value={this.state.value.content}
              modules={this.modules}
              formats={this.formats}
              onChange={(content) =>
                this.setState({
                  ...this.state,
                  value: { ...this.state.value, content },
                })
              }
            />
          </Form.Item>
          <Form.Item name="thumbnail">
            <FullWidth>
              <Upload
                name="file"
                action="https://api.arbancityhotel.com/upload"
                onChange={this.onChangeThumbnail}
                fileList={this.state.value.thumbnail}
                withCredentials
              >
                <Button>
                  <UploadOutlined /> 썸네일
                </Button>
              </Upload>
            </FullWidth>
          </Form.Item>
          <Form.Item>
            <Space>
              <Button
                icon={<CheckOutlined />}
                type="primary"
                onClick={this.onFinish}
                style={{
                  backgroundColor: "#0080a3",
                  borderColor: "#0080a3",
                }}
              >
                {this.state.actionTitle}
              </Button>
              <Popconfirm
                title={`게시글 ${this.state.actionTitle}을 취소하시겠습니까?`}
                onConfirm={this.confirm}
                okText="예"
                cancelText="아니오"
              >
                <Button icon={<CloseOutlined />}>취소</Button>
              </Popconfirm>
            </Space>
          </Form.Item>

          <input
            style={{ display: "none" }}
            ref={"images"}
            name="images"
            type="file"
            multiple
            onChange={this.onChangeImages}
          />
        </Form>
      </div>
    );
  }
}

const FullWidth = styled.div`
  button,
  div {
    width: 100%;
  }
`;

const Quill = styled(ReactQuill)`
  .ql-editor {
    min-height: 256px;
  }

  .ql-container {
    border-top: none;
    border: 1px solid #d9d9d9;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 3px;
  }

  .ql-toolbar {
    border: 1px solid #d9d9d9;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }

  ${Font.whitelist.map((item) => {
    return `
      .ql-font span[data-label="${item}"]::before {
        font-family: "${item}" !important;
      }

      .ql-picker-label[data-value="${item}"]::before {
        font-family: "${item}" !important;
        content: "${item.replace("_", " ")}" !important;
      }

      .ql-picker-item[data-value="${item}"]::before {
        font-family: "${item}" !important;
        content: "${item.replace("_", " ")}" !important;
      }
    `;
  })}
`;

export default withRouter(Editor);
