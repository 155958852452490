import React, { useState, useEffect } from "react";
import { Layout } from "antd";
import MainLayout from "./components/MainLayout";
import Login from "./components/Login";
import "./App.css";
import AuthService from "./services/auth";

function App() {
  const [render, setRender] = useState();

  const auth = () => {
    AuthService.ping()
      .then(() => {
        setRender(<MainLayout />);
      })
      .catch(() => {
        setRender(<Login />);
      });
  };

  useEffect(() => {
    auth();
  }, []);

  return <Layout style={{ minHeight: "100vh" }}>{render}</Layout>;
}

export default App;
