import { OrderedListOutlined } from "@ant-design/icons";
import { Button, Layout, Menu } from "antd";
import React, { useEffect, useState } from "react";
import { Link, Redirect, Route, Switch, withRouter } from "react-router-dom";
import styled from "styled-components";
import ApiTable from "../components/ApiTable";
import Editor from "../components/Editor";
import Post from "../components/Post";
import AuthService from "../services/auth";

const { Header, Content, Sider } = Layout;

function MainLayout(props) {
  const [currentUrl, setCurrentUrl] = useState(props.location.pathname);

  const handleSelect = ({ item }) => {
    setCurrentUrl(item.props.eventKey);
  };

  useEffect(() => {
    setCurrentUrl(props.location.pathname);
  });

  const Table = () => <ApiTable />;

  return (
    <MainLayoutWrap>
      <Sider
        style={{
          backgroundColor: "#0080a3",
        }}
      >
        <div
          style={{
            margin: "32px 10px",
            height: 32,
            background: "url(/logo-white.png)",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        ></div>
        <Menu
          theme="dark"
          mode="inline"
          onSelect={handleSelect}
          selectedKeys={currentUrl}
          style={{
            backgroundColor: "#0080a3",
          }}
        >
          <Menu.Item key="/special/package" name="PACKAGE">
            <Link to="/special/package">PACKAGE</Link>
          </Menu.Item>
          <Menu.Item key="/special/event" name="EVENT">
            <Link to="/special/event">EVENT</Link>
          </Menu.Item>
          <Menu.Item key="/special/notice" name="NOTICE">
            <Link to="/special/notice">NOTICE</Link>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout" style={{ backgroundColor: "white" }}>
        <Header
          className="site-layout-background"
          style={{
            padding: 0,
            display: "flex",
            flexDirection: "row-reverse",
            alignItems: "center",
            backgroundColor: "white",
          }}
        >
          <Logout onClick={AuthService.logout} ghost>
            로그아웃
          </Logout>
        </Header>
        <Content>
          <Switch>
            <Route exact path="/special/package" component={Table} />
            <Route exact path="/special/event" component={Table} />
            <Route exact path="/special/notice" component={Table} />
            <Route exact path="/wedding/gallery" component={Table} />
            <Route exact path="/editor" component={Editor} />
            <Route exact path="/special/package/:id" component={Post} />
            <Route exact path="/special/event/:id" component={Post} />
            <Route exact path="/special/notice/:id" component={Post} />
            <Route exact path="/wedding/gallery/:id" component={Post} />
            <Route>
              <Redirect to="/special/package"></Redirect>
            </Route>
          </Switch>
        </Content>
      </Layout>
    </MainLayoutWrap>
  );
}

const MainLayoutWrap = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;

  .ant-menu > .ant-menu-item-selected {
    background-color: #00708f !important;
  }
`;

const Logout = styled(Button)`
  margin-right: 48px;
  border-color: #0080a3;
  background-color: #0080a3;
  color: #0080a3;
`;

export default withRouter(MainLayout);
