import axios from "./axios";

const API_URL = "/auth";

class AuthService {
  login(username, password) {
    return axios.post(API_URL, {
      username,
      password,
    });
  }

  logout() {
    axios
      .delete(API_URL)
      .then((response) => {
        window.location.href = "/login";
      })
      .catch((err) => {
        console.log(err);
      });
  }

  ping() {
    return axios.get(API_URL);
  }
}

export default new AuthService();
